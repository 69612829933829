import './App.css';
import Results from './Screens/Results/Results';

function App() {
  return (
    <div>
      {/* <Search/> */}
      <Results/>
    </div>
  );
}

export default App;
