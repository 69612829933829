import React, { useState,useEffect } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../Images/Logo.png';
import ResultBox from '../../Components/ResultBox/ResultBox';

import LinearProgress from '@mui/material/LinearProgress';

import './Results.css'

export default function Results() {
    const API_KEY = "AIzaSyC2k_8biKq4GLGiIruZUZalVmZsExOnnTQ";
    const SEARCH_ENGINE_ID = "15306462b8f5d45a2";

    const [SearchQuery, setSearchQuery] = useState("");
    const [SearchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const encodedQuery = encodeURIComponent(SearchQuery);

    const [selectedPage, setSelectedPage] = useState(0);
    const [searchIndex, setSearchIndex] = useState(1);
    const [noOfPages, setNoOfPages] = useState([1]);
    const [activePage,setActivePage]=useState(1);

    const handleChange = (e) => {
        setSearchQuery(e.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            zeroSearchIndex();
        }
    };

    const handleSearch = () => {
        setLoading(true);
        setSearchResults([]);
        axios.get(`https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=${SEARCH_ENGINE_ID}&q=${encodedQuery}&start=${searchIndex}`)
            .then(response => {
                setSearchResults(response.data.items);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    const handleNextPage = () => {
        const lastNumber = noOfPages[noOfPages.length - 1];
        const nextNumber = lastNumber + 1;
        if(lastNumber===activePage){
            setNoOfPages([...noOfPages, nextNumber]);
        }
        setSelectedPage(selectedPage+1);
        const pageValue=selectedPage+1;
        const indexValue=pageValue*10+1;
        setSearchIndex(indexValue);
        setActivePage(pageValue+1);
    };

    const handlePreviousPage = () => {
        if (activePage === 1) {
            return;
        }
        const lastNumber = noOfPages[noOfPages.length - 1];
        if(lastNumber===activePage){
            const updatedPages = noOfPages.slice(0, -1);
            setNoOfPages(updatedPages);
        }
        setSelectedPage(selectedPage-1);
        const pageValue=selectedPage-1;
        const indexValue=pageValue*10+1;
        setSearchIndex(indexValue);
        
        const nextNumber = lastNumber - 1;
        setActivePage(pageValue+1);
    };

      const zeroSearchIndex=()=>{
        setNoOfPages([1]);
        setSelectedPage(0);
        setSearchIndex(1);
        setActivePage(1);
        if(searchIndex===1){
            handleSearch();
        }
      }

      const selectPageHandler=(pageNo)=>{
        setSelectedPage(pageNo-1);
        const selectedPage=pageNo-1;
        const indexValue=selectedPage*10+1;
        setSearchIndex(indexValue);
        setActivePage(pageNo);
      }

      useEffect(() => {
        handleSearch();
      }, [searchIndex]);


    return (
        <div>
            <div className='searchBarContainer'>
                <div className='searchBarInnerContainer'>
                    <img src={Logo} alt="Logo" className='logo'></img>
                    <div className='resultsSearch-bar'>
                        <input type='text' className='resultsSearchInput' placeholder='Search' value={SearchQuery} onChange={handleChange} onKeyDown={handleKeyDown}></input>
                        <button className='resultsSearchResults' onClick={zeroSearchIndex}>
                            <FontAwesomeIcon icon={faSearch} className='resultsSearchIcon' />
                        </button>
                    </div>
                </div>
            </div>
            {loading === true ? (
                <LinearProgress
                    sx={{
                        backgroundColor: '#aae1d6',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#56C1AB',
                        },
                    }}
                />
            ) : <div></div>}
            <div className='displayResultsContainer'>
                {SearchResults.map((result, index) => (
                    <ResultBox key={index} title={result.title} link={result.link} snippet={result.snippet} />
                ))}
            </div>
            {SearchResults.length > 0 ? (
                <div className='Pagination'>
                    <button className='paginationArrowButton' onClick={handlePreviousPage}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    {noOfPages.map((page,index)=>(
                        <button className={`paginationArrowButton ${page===activePage?'paginationButtonActive':''}`} key={index} onClick={()=>selectPageHandler(page)}>{page}</button>
                    ))}
                    <button className='paginationArrowButton' onClick={handleNextPage}>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                </div>
            ) : <div></div>}

        </div>
    )
}
