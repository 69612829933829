import React from 'react'
import './ResultBox.css'

export default function ResultBox(props) {
    return (
        <div className='resultBox'>
            <div className='logoAndLink'>
                {/* <img src={props.imageLink} className='reultsLogo'></img> */}
                <div className='linkTitle'><a href={props.link} target="_blank" rel="noopener noreferrer" className='titleLink'>{props.title}</a></div>
                <p className='displayLink'>{props.link}/</p>
            </div>
            <div className='snippet'>
                {props.snippet}
            </div>
        </div>
    )
}
